import { Fragment, memo, useMemo } from 'react';
import { getCaptionsLineHeight, getCaptionStyles } from '../utils';
import useCaptionsStylesHook from '@/components/molecules/useCaptionsStylesHook';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import { CaptionStyle, ClipMetadata, HighlightType } from '@/domains/asset';
import { Word } from '@/domains/transcript';
import { getFontByLocation } from '@/Pages/Clip/SideBar/FontSelector/constants';
import { classnames } from '@/libs/utils';
import { getCurrentClipFontName, getFontNameFromBrandKit } from '@/stores/brandKit';
import { loadFont } from '@/libs/fonts';
import { PreviewTemplate } from '@/Pages/Clip/SideBar/types';

function CaptionsWords({
  words,
  clipMetadata,
  captionFontSize,
  animation,
  scale = 1,
  highlightType = 'none',
  classNames = '',
  isPreview = false,
  previewIndex = 0
}: {
  words: Word[];
  clipMetadata: ClipMetadata | PreviewTemplate;
  captionFontSize: number;
  animation?: CaptionStyle;
  scale?: number;
  highlightType?: HighlightType;
  isPreview?: boolean;
  classNames?: string;
  previewIndex?: number;
}) {
  const { playerStore, clipId } = useClipsContext();
  const { activeWordStyles, inactiveWordStyles } = useCaptionsStylesHook(clipMetadata, highlightType);

  const captionsStyles = useMemo(() => {
    return getCaptionStyles(animation, scale, getFontByLocation(clipMetadata.font_location));
  }, [animation, scale, clipMetadata.font_location]);

  const lineHeight = useMemo(() => {
    return getCaptionsLineHeight(captionFontSize);
  }, [captionFontSize]);

  const textColor = useMemo(() => clipMetadata?.magicLayout?.textColor, [clipMetadata?.magicLayout?.textColor]);

  const activeTextColor = useMemo(() => {
    return highlightType !== 'none' && !!clipMetadata.text_highlight_color
      ? clipMetadata.text_highlight_color
      : clipMetadata?.magicLayout?.textColor;
  }, [clipMetadata?.magicLayout?.textColor, highlightType, clipMetadata.text_highlight_color]);

  const fontFamilyStyle = useMemo(() => {
    const fontItem = getFontByLocation(clipMetadata.font_location);
    if (!!fontItem) {
      loadFont(fontItem.url, fontItem.name);
      return { fontFamily: fontItem.name };
    }
    // No standard font, get from brand kit
    const currentClipFontName = getCurrentClipFontName(clipId);
    if (currentClipFontName) return { fontFamily: currentClipFontName };

    const brandKitFontName = getFontNameFromBrandKit(clipMetadata.font_location);
    if (brandKitFontName) return { fontFamily: brandKitFontName };

    return {};
  }, [clipId, clipMetadata.font_location]);

  return words.map((word, index) => {
    const isActive = isPreview
      ? index === previewIndex
      : word.start_time <= playerStore.currentTime && word.end_time >= playerStore.currentTime;
    const wordStyles = isActive ? activeWordStyles : inactiveWordStyles;
    const animationStyles = isActive && highlightType === 'box' ? {} : captionsStyles;
    if (highlightType === 'text') {
      wordStyles.opacity = !isActive ? 0.6 : 1;
    }
    const isLastWord = index === words.length - 1;

    return (
      <Fragment key={word.index}>
        <span
          className={classnames('bg-clip-content', classNames)}
          style={{
            height: `${lineHeight}px`,
            color: isActive ? activeTextColor : textColor,
            backgroundColor: wordStyles.backgroundColor,
            WebkitFontSmoothing: 'antialiased',
            opacity: wordStyles.opacity,
            ...(fontFamilyStyle.fontFamily ? { fontFamily: fontFamilyStyle.fontFamily } : {}),
            ...animationStyles
          }}
        >
          {word.content.trim()}
        </span>
        {!isLastWord && (
          <span style={fontFamilyStyle.fontFamily ? { fontFamily: fontFamilyStyle.fontFamily } : {}}>&nbsp;</span>
        )}
      </Fragment>
    );
  });
}

export default memo(CaptionsWords);
