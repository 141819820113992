import { IconSparkles } from '@tabler/icons-react';
import { assetsTextDestroy } from '@goldcast/api/content';
import PostListItem from './PostListItem';
import { PostListProps, TextAsset } from '../types';
import { POST_TYPE_LABELS, POST_TYPE_ICONS } from '../constants';
import PostListSkeletonLoader from './PostListSkeletonLoader';
import PostAssetGenerationEmptyState from './PostAssetGenerationEmptyState';
import GenerateMoreAITextButton from './GenerateMoreAITextButton';
import Button from '@/components/atoms/Button/Button';
import Icon from '@/components/atoms/Icon';
import { classnames } from '@/libs/utils';
import useElementScrolledHook from '@/hooks/useElementScrolledHook';
import IconButton from '@/components/atoms/Button/IconButton';
import AssetRatingDialog from '@/components/molecules/AssetRating/AssetRatingDialog';
import useSavedSearch from '@/hooks/useSavedSearch';

export default function PostList({
  posts,
  selectedPost,
  postType,
  isGeneratingPost,
  isLoading,
  isCollapsed,
  onDelete,
  onPostSelected,
  onGeneratePost,
  toggleCollapse,
  isGeneratingAssets,
  refetch
}: PostListProps) {
  const { isScrolled, handleOnScroll } = useElementScrolledHook();
  const { isSavedSearchPage } = useSavedSearch();

  const deletePost = async (postId: string) => {
    assetsTextDestroy({ id: postId }).then(() => {
      onDelete(postId);
    });
  };

  return (
    <>
      <div className="absolute bottom-0 h-[69vh] w-full bg-gradient-to-t from-slate-950/[0.13] to-slate-950/0 blur"></div>
      <div className="z-20 flex w-full shrink-0 flex-col items-stretch overflow-hidden rounded-2xl border !border-slate-100 bg-white shadow-xl shadow-slate-200/10 transition-all duration-[400ms]">
        <div className="flex h-full flex-col">
          <div
            className={classnames(
              'sticky top-0 z-20 flex h-14 w-full items-center justify-between bg-white/90 p-4 px-5 backdrop-blur transition-all duration-150 ease-in-out',
              {
                'shadow-lg shadow-slate-900/[0.075]': isScrolled
              }
            )}
          >
            <div className="flex items-center space-x-2">
              <span className="text-lg font-medium">{POST_TYPE_LABELS[postType]}</span>
              {!isLoading && (
                <span className="inline-flex rounded-full bg-slate-200 px-2.5 py-0.5 text-xs font-medium">
                  {posts.length}
                </span>
              )}
            </div>
            <div className="flex space-x-1.5">
              <IconButton
                icon={isCollapsed ? 'IconArrowBarRight' : 'IconArrowBarLeft'}
                title={isCollapsed ? 'Expand Sidebar' : 'Collapse Sidebar'}
                variation="text"
                trackingId="clips-list-collapse-button"
                onClick={toggleCollapse}
              />
            </div>
          </div>
          <div className="flex grow flex-col overflow-y-auto overflow-x-hidden" onScroll={handleOnScroll}>
            {isLoading ? (
              <PostListSkeletonLoader isCollapsed={isCollapsed} />
            ) : (
              <>
                {!posts.length &&
                  (!isGeneratingAssets || isSavedSearchPage ? (
                    <div className="flex h-full w-full grow items-center justify-center">
                      <div className="">
                        <div className="flex w-full justify-center">
                          <Icon name={POST_TYPE_ICONS[postType]} className="h-7 w-7 text-slate-400" />
                        </div>
                        <div className="mt-2.5 px-4 text-center text-sm text-slate-400">
                          <div className="font-medium">No {POST_TYPE_LABELS[postType]} post, yet.</div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <PostAssetGenerationEmptyState postType={postType} />
                  ))}
              </>
            )}
            {posts.length && !isLoading ? (
              posts.map((post: TextAsset) => (
                <div key={post.id}>
                  <PostListItem
                    post={post}
                    selected={selectedPost?.id === post.id}
                    isCollapsed={isCollapsed}
                    handleSelectedPost={onPostSelected}
                    onDelete={deletePost}
                  />
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
          {isSavedSearchPage ? (
            <GenerateMoreAITextButton refetch={refetch} />
          ) : (
            !isGeneratingAssets && (
              <div className="flex items-center justify-center border-t bg-white px-6 py-3">
                <Button
                  className="h-12 w-full px-6 py-2 !text-sm"
                  variation="filled"
                  onClick={onGeneratePost}
                  trackingId="generate-post-click"
                  disabled={isGeneratingPost}
                >
                  <IconSparkles size={24} stroke={2} className="mr-1" /> Generate {POST_TYPE_LABELS[postType]} Post
                </Button>
              </div>
            )
          )}
        </div>
      </div>
      <AssetRatingDialog />
    </>
  );
}
