import { init, FeatureFlag } from '@goldcast/feature-flags';
import { getEnvConfig } from '@/constants';
import featureFlagStore from '@/stores/featureFlagStore';

type FeatureFlags = {
  [_ in FeatureFlagKeys]: FeatureFlag;
};

export enum FeatureFlagKeys {
  /* PERMANENT FLAGS */
  Use_CL_AI_Debug_Tools = 'Use_CL_AI_Debug_Tools',
  /* TEMPORARY FLAGS */
  Use_CL_New_Timeline = 'Use_CL_New_Timeline',
  Use_CL_Captions_Overlay = 'Use_CL_Captions_Overlay',
  Use_CL_Easy_Clip_Customizer = 'Use_CL_Easy_Clip_Customizer',
  Use_CL_Facial_Rec_Increment_AB = 'Use_CL_Facial_Rec_Increment_AB',
  Use_CL_Fast_Accurate = 'Use_CL_Fast_Accurate',
  Use_CL_AI_Clips = 'Use_CL_AI_Clips',
  Use_CL_Brand_Tone = 'Use_CL_Brand_Tone',
  Use_CL_FTUX_Clip_Templates = 'Use_CL_FTUX_Clip_Templates',
  Use_CL_FTUX_Phase2 = 'Use_CL_FTUX_Phase2',
  Use_CL_AI_Social_Posts = 'Use_CL_AI_Social_Posts'
}

export const handleFlagValueChange = (flag: FeatureFlagKeys) => (value: boolean) => {
  featureFlagStore.update(data => ({
    ...data,
    [flag]: value
  }));
};

export const featureFlags = (): FeatureFlags => ({
  [FeatureFlagKeys.Use_CL_AI_Debug_Tools]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_AI_Debug_Tools)
  },
  [FeatureFlagKeys.Use_CL_New_Timeline]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_New_Timeline)
  },
  [FeatureFlagKeys.Use_CL_Captions_Overlay]: {
    value: true,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_Captions_Overlay)
  },
  [FeatureFlagKeys.Use_CL_Easy_Clip_Customizer]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_Easy_Clip_Customizer)
  },
  [FeatureFlagKeys.Use_CL_Facial_Rec_Increment_AB]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_Facial_Rec_Increment_AB)
  },
  [FeatureFlagKeys.Use_CL_Fast_Accurate]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_Fast_Accurate)
  },
  [FeatureFlagKeys.Use_CL_AI_Clips]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_AI_Clips)
  },
  [FeatureFlagKeys.Use_CL_Brand_Tone]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_Brand_Tone)
  },
  [FeatureFlagKeys.Use_CL_FTUX_Clip_Templates]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_FTUX_Clip_Templates)
  },
  [FeatureFlagKeys.Use_CL_AI_Social_Posts]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_AI_Social_Posts)
  },
  [FeatureFlagKeys.Use_CL_FTUX_Phase2]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_FTUX_Phase2)
  }
});

// store promise in var for cases of calling this function from multiple places
let initializationPromise: Promise<void> | null = null;

export const initializeFeatureFlags = user => {
  if (featureFlagStore.getSnapshot().areFlagsLoaded) return Promise.resolve();
  if (!initializationPromise) {
    initializationPromise = new Promise<void>((resolve, reject) => {
      init(
        getEnvConfig('APP_HARNESS_SECRET') || '',
        {
          identifier: user.id || 'anonymous',
          name: `${user.first_name} ${user.last_name}` || 'anonymous',
          attributes: {
            role: 2, // admin role
            event: '',
            eventOrganization: user.organization,
            is_content_lab_standalone: user.is_content_lab_standalone,
            appHostname: window.location?.hostname
          }
        },
        featureFlags(),
        {
          ready: () => {
            featureFlagStore.update(data => ({ ...data, areFlagsLoaded: true }));
            return resolve();
          },
          errored: () => {
            featureFlagStore.update(data => ({ ...data, areFlagsLoaded: true }));
            return reject();
          },
          errorAuth: () => {
            featureFlagStore.update(data => ({ ...data, areFlagsLoaded: true }));
            return reject();
          }
        }
      );
    });
  }
  return initializationPromise;
};
