import { PREVIEW_SPEAKER_IMAGE } from '../../MockTemplateBackgroundTransition/constants';
import { Pronouns } from '@/domains/speaker';
import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';
import { WordType } from '@/domains/transcript';

export const TEMPLATE_SELECT_MAX_COUNT = 5;

export const TEMPLATE_SELECT_PREVIEW_FIRST_SPEAKER: SpeakerWithDetails = {
  first_name: 'Devin',
  last_name: 'Reed',
  color: 'text-teal-500',
  bgColor: 'bg-teal-500',
  id: '1',
  face_ids: [],
  company: '',
  title: '',
  parent_id: null,
  profile_picture_url: PREVIEW_SPEAKER_IMAGE,
  pronouns: Pronouns.HE,
  speakingSlots: {
    '0.16': 15.335
  },
  video: {
    start: 0,
    url: 'https://www.example.com'
  },
  key: 'spk_0'
};

export const TEMPLATE_SELECT_PREVIEW_MOCK_CLIP = {
  content: {
    media_source_type: 'UPLOAD'
  },
  first_frame_analysis: {
    fps: 30,
    clip_id: 'mock-broadcast-id',
    accurate: false,
    face_ids: [0, 1],
    true_end: 193055.01,
    true_start: 158665,
    time_analysis: [
      {
        time: '0:00:00.0',
        frame_number: 0,
        face_positions: [
          {
            face_id: 0,
            spk_label: '',
            crop_position: {
              top_left: {
                x: 0.1109,
                y: 0.3
              },
              bottom_right: {
                x: 0.4297,
                y: 0.7343
              }
            },
            crop_position_face: {
              top_left: {
                x: 0.2083,
                y: 0.3426
              },
              bottom_right: {
                x: 0.3115,
                y: 0.6111
              }
            },
            crop_position_person: {
              top_left: {
                x: 0.1109,
                y: 0.3
              },
              bottom_right: {
                x: 0.4297,
                y: 0.7343
              }
            }
          },
          {
            face_id: 1,
            spk_label: '',
            crop_position: {
              top_left: {
                x: 0.6016,
                y: 0.2806
              },
              bottom_right: {
                x: 0.9411,
                y: 0.7454
              }
            },
            crop_position_face: {
              top_left: {
                x: 0.7188,
                y: 0.3269
              },
              bottom_right: {
                x: 0.8161,
                y: 0.575
              }
            },
            crop_position_person: {
              top_left: {
                x: 0.6016,
                y: 0.2806
              },
              bottom_right: {
                x: 0.9411,
                y: 0.7454
              }
            }
          }
        ]
      }
    ],
    speaker_mapping: {
      '1': [1]
    },
    analysis_version: 'v0.7',
    face_id_overrides: {
      '0': [0],
      '1': [1]
    },
    new_face_id_overrides: {},
    active_speaker_mapping: {},
    manual_speaker_mapping: {}
  },
  layout_status: {
    GRID: { status: 'DONE', accurate: false },
    SPEAKER: { status: 'DONE', accurate: false }
  },
  broadcast: 'mock-broadcast-id',
  broadcast_data: {
    title: 'Mock Broadcast'
  },
  id: 'mock-clip-id'
};

export const INTERIM_THUMBNAIL_PREVIEW_WORDS = [
  {
    start_time: 16,
    end_time: 18,
    content: 'Your',
    type: WordType.Pronunciation,
    speaker_label: 'sp_1',
    srt_index: 1,
    index: 0,
    hidden: false
  },
  {
    start_time: 20,
    end_time: 25,
    content: 'captions',
    type: WordType.Pronunciation,
    speaker_label: 'sp_1',
    srt_index: 1,
    index: 1,
    hidden: false
  },
  {
    start_time: 25,
    end_time: 30,
    content: 'go',
    type: WordType.Pronunciation,
    speaker_label: 'sp_1',
    srt_index: 1,
    index: 2,
    hidden: false
  },
  {
    start_time: 30,
    end_time: 35,
    content: 'here',
    type: WordType.Pronunciation,
    speaker_label: 'sp_1',
    srt_index: 1,
    index: 3,
    hidden: false
  }
];
