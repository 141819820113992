import { AiClipPromptOption } from './types';
import { FeatureFlagKeys } from '@/services/featureFlag';

export const SEARCH_OPTIONS: AiClipPromptOption[] = [
  {
    icon: 'IconPlayerPlay',
    label: 'Find new clips about …',
    type: 'CLIP',
    description: 'Clips'
  },
  {
    icon: 'IconThumbUp',
    label: 'Generate social posts about …',
    type: 'SOCIAL',
    featureFlag: FeatureFlagKeys.Use_CL_AI_Social_Posts,
    description: 'Social posts'
  }
];

export enum AI_SEARCH_ERROR_CODES {
  CLIP_NOT_EXIST_FOR_SEARCH_TERM = 'CLIP_NOT_EXIST_FOR_SEARCH_TERM'
}
