import { getSpeakerNameAndTitle, getTruncatedName, getTruncatedTitle } from '../CompositePlayer/SpeakerVideoUtils';
import { speakersAnalysisStore } from '@/stores/speakersAnalysis/speakersAnalysis';
import { TranscriptContextData } from '@/context/TranscriptContext/TranscriptContextTypes';
import { getSizeConfig } from '@/libs/sharedAPI/sizeConfig/SizeConfigFactory';
import { Clip } from '@/domains/asset';
import { FacePositions } from '@/stores/speakersAnalysis/speakersAnalysisTypes';

export function drawSpeakerNameTitlePillForSpeakerRecording(
  staticCanvasContext,
  secondaryPlayers,
  currentSpeaker,
  showCaption,
  staticSizeConfig,
  clipLayout,
  clipSize,
  clipData,
  transcriptStore
) {
  const videoRef = secondaryPlayers.find(player => player.current?.id === currentSpeaker?.key);
  if (!videoRef?.current) return;

  const finalCropPositions = staticSizeConfig.getCropPositions({
    videoWidth: videoRef.current.videoWidth,
    videoHeight: videoRef.current.videoHeight,
    showCaption,
    totalVideos: 1,
    currentVideoNumber: 1
  });

  const speaker = transcriptStore.speakersWithDetails[videoRef.current.id];
  if (!speaker) return;
  const truncatedTitle = getTruncatedTitle(speaker, clipLayout, clipSize);
  const truncatedName = getTruncatedName(`${speaker.first_name} ${speaker.last_name}`, clipLayout, clipSize);
  staticSizeConfig.drawSpeakerNameTitlePill(staticCanvasContext, {
    name: truncatedName,
    title: truncatedTitle,
    backgroundColor: clipData.asset_metadata.magicLayout?.textColor,
    textColor: clipData.asset_metadata.magicLayout?.backgroundColor,
    crop_x: finalCropPositions.target_crop_x,
    crop_y: finalCropPositions.target_crop_y,
    height: finalCropPositions.target_crop_height,
    currentVideoNumber: 1,
    totalNumber: 1,
    fontLocation: clipData.asset_metadata.font_location
  });
}

export function drawSpeakerNameTitlePillForGridRecording(
  staticCanvasContext,
  secondaryPlayers,
  showCaption,
  staticSizeConfig,
  clipLayout,
  clipSize,
  clipData,
  visible_speakers
) {
  visible_speakers?.forEach((speaker, index) => {
    const player = secondaryPlayers.find(player => player.current?.id === speaker.key);

    if (!player || !player.current) return;
    const finalCropPositions = staticSizeConfig.getCropPositions({
      videoWidth: player.current.videoWidth,
      videoHeight: player.current.videoHeight,
      showCaption,
      totalVideos: visible_speakers.length,
      currentVideoNumber: index + 1
    });

    if (!speaker) return;
    const truncatedTitle = getTruncatedTitle(speaker, clipLayout, clipSize);
    const truncatedName = getTruncatedName(`${speaker.first_name} ${speaker.last_name}`, clipLayout, clipSize);

    staticSizeConfig.drawSpeakerNameTitlePill(staticCanvasContext, {
      name: truncatedName,
      title: truncatedTitle,
      backgroundColor: clipData.asset_metadata.magicLayout?.textColor,
      textColor: clipData.asset_metadata.magicLayout?.backgroundColor,
      crop_x: finalCropPositions.target_crop_x,
      crop_y: finalCropPositions.target_crop_y,
      height: finalCropPositions.target_crop_height,
      currentVideoNumber: index + 1,
      totalNumber: visible_speakers.length,
      fontLocation: clipData.asset_metadata.font_location
    });
  });
}

export function drawSpeakerNameTitlePillForUpload(
  staticCanvasContext: CanvasRenderingContext2D,
  mainPlayer: React.RefObject<HTMLVideoElement>,
  showCaption: boolean,
  staticSizeConfig: ReturnType<typeof getSizeConfig>,
  clipData: Pick<Clip, 'id' | 'asset_metadata'>,
  transcriptStore: TranscriptContextData,
  facePositions: FacePositions[]
) {
  facePositions.forEach((facePosition, index) => {
    const cropPosition = facePosition.crop_position || facePosition.crop_position_face;
    if (!cropPosition) return;

    const speaker = Object.values(transcriptStore.speakersWithDetails).find(speaker =>
      speakersAnalysisStore.getSnapshot()[clipData.id]?.speaker_mapping?.[speaker.id]?.includes(facePosition.face_id)
    );
    if (!speaker) return;

    const finalCropPositions = staticSizeConfig.getCropPositions({
      videoWidth: mainPlayer.current!.videoWidth * (cropPosition.bottom_right.x - cropPosition.top_left.x),
      videoHeight: mainPlayer.current!.videoHeight * (cropPosition.bottom_right.y - cropPosition.top_left.y),
      showCaption,
      source_crop_x_offset: Math.round(mainPlayer.current!.videoWidth * cropPosition.top_left.x),
      source_crop_y_offset: Math.round(mainPlayer.current!.videoHeight * cropPosition.top_left.y),
      totalVideos: facePositions.length,
      currentVideoNumber: index + 1
    });

    const { name, title } = getSpeakerNameAndTitle(speaker);

    staticSizeConfig.drawSpeakerNameTitlePill(staticCanvasContext, {
      name,
      title,
      backgroundColor: clipData.asset_metadata.magicLayout?.textColor,
      textColor: clipData.asset_metadata.magicLayout?.backgroundColor,
      crop_x: finalCropPositions.target_crop_x,
      crop_y: finalCropPositions.target_crop_y,
      height: finalCropPositions.target_crop_height,
      currentVideoNumber: index + 1,
      totalNumber: facePositions.length,
      fontLocation: clipData.asset_metadata.font_location
    });
  });
}
