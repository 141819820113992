import { memo, useState, useSyncExternalStore } from 'react';
import { assetsSavedSearchGenerateTextAssetsCreate } from '@goldcast/api/content';
import IconButton from '@/components/atoms/Button/IconButton';
import { savedAISearch } from '@/stores/savedAISearch';
import { useAppContext } from '@/context/AppContext/AppContext';
import { showErrorToast } from '@/libs/toast/toast';

function GenerateMoreAITextButton({ refetch }: { refetch: () => void }) {
  const [isLoading, setIsLoading] = useState(false);
  const savedAiSearchStore = useSyncExternalStore(savedAISearch.subscribe, savedAISearch.getSnapshot);
  const { logger } = useAppContext();

  function generateMore() {
    setIsLoading(true);
    assetsSavedSearchGenerateTextAssetsCreate({ id: savedAiSearchStore!.id })
      .then(() => {
        refetch();
      })
      .catch(e => {
        showErrorToast(
          'Whoops! We encountered an issue while attempting to generate more Social Posts. Please try again.'
        );
        logger.error(`Error generating more AI Social Posts: ${e}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <div className="flex items-center justify-center border-t bg-white px-6 py-3">
      {isLoading ? (
        <IconButton
          icon="IconLoader2"
          content=""
          variation="filled"
          size="full"
          disabled={true}
          iconClassName="animate-spin"
          trackingId="loading-more-ai-search-clips-button"
        />
      ) : (
        <IconButton
          icon="IconSparkles"
          content="Generate More"
          variation="filled"
          disabled={isLoading}
          size="full"
          trackingId="genereate-more-ai-search-clips-button"
          onClick={generateMore}
        />
      )}
    </div>
  );
}

export default memo(GenerateMoreAITextButton);
