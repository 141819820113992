import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { IconVideo } from '@tabler/icons-react';
import ClipInterimThumbnail from './ClipInterimThumbnail';
import { Clip } from '@/domains/asset';
import { useTranscriptContext } from '@/context/TranscriptContext/TranscriptContext';
import { getClipThumbnailUrl } from '@/Pages/Sessions/AssetsTable/ClipPreview/ClipPreviewUtils';
import { useAppContext } from '@/context/AppContext/AppContext';

const ClipListItemInterimThumbnail = ({ clip }: { clip: Clip }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);

  const transcriptStore = useTranscriptContext();
  const { logger } = useAppContext();

  const clipThumbnailUrl = getClipThumbnailUrl(clip);
  const clipThumbnailRef = useRef<HTMLImageElement>(null);

  const firstSpeaker = useMemo(() => {
    const firstSpeakerWord = transcriptStore.transcript.find(word => {
      return !word.hidden && clip.asset_metadata.start <= word.end_time;
    });
    return transcriptStore.usedSpeakers.find(speaker => speaker.key === firstSpeakerWord?.speaker_label);
  }, [clip.asset_metadata.start, transcriptStore.usedSpeakers, transcriptStore.transcript]);

  const interimThumbnailCaptions = useMemo(() => {
    const srtIndex = transcriptStore.transcript.find(
      word => word.start_time >= clip.asset_metadata.start && !word.hidden && word.end_time <= clip.asset_metadata.end
    )?.srt_index;
    return transcriptStore.transcript
      .filter(word => {
        return word.srt_index === srtIndex;
      })
      .slice(0, 4);
  }, [clip.asset_metadata.end, clip.asset_metadata.start, transcriptStore.transcript]);

  const onImageLoaded = useCallback(() => {
    setImageLoaded(true);
    clipThumbnailRef.current?.setAttribute('currentTime', clip.asset_metadata.start.toString());
  }, [clip.asset_metadata.start]);

  const onImageLoadError = useCallback(() => {
    logger.error('Clip does not have a thumbnail', { clipId: clip.id });
    setImageError(true);
  }, [clip.id]);

  return (
    <ClipInterimThumbnail
      clip={clip}
      firstSpeaker={firstSpeaker}
      imageLoaded={imageLoaded}
      clipThumbnailRef={clipThumbnailRef}
      captions={interimThumbnailCaptions}
    >
      {!imageError ? (
        <img
          alt="Preview"
          className="h-full w-full object-cover"
          src={clipThumbnailUrl}
          ref={clipThumbnailRef}
          onLoad={onImageLoaded}
          onError={onImageLoadError}
        />
      ) : (
        <div className="absolute inset-0 flex items-center justify-center bg-slate-200/60">
          <IconVideo className="h-5 w-5 text-slate-400" />
        </div>
      )}
    </ClipInterimThumbnail>
  );
};

export default memo(ClipListItemInterimThumbnail);
