import { clipsNeedingConfigUpdateStore, updateVisibleFaceIdsInClip } from '../clip';
import createStore from '../store';
import { SpeakersAnalysis, SpeakersAnalysisStore } from './speakersAnalysisTypes';
import { getEnv } from '@/libs/env';
import { AppContextState } from '@/context/AppContext/AppContextTypes';
import { roundToNDecimalPlaces, timeStringToSeconds } from '@/libs/utils';

export const defaultSpeakersAnalysis: SpeakersAnalysis = {
  face_ids: [],
  time_analysis: [],
  times: [],
  visible_face_ids: [],
  speaker_mapping: {},
  loading: true
};

export function initSpeakersAnalysisStore(clipId: string) {
  updateSpeakersAnalysisStoreWithId(clipId, defaultSpeakersAnalysis);
}

export const speakersAnalysisStore = createStore<SpeakersAnalysisStore>({});

export function getSpeakersAnalysis(
  id: string,
  start: number,
  visible_face_ids: number[],
  isAnalysisAccurate?: boolean
): Promise<void> {
  return fetch(`${getEnv('VISION_URL')}/analysis/clips/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.accessToken}`
    },
    mode: 'cors'
  })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      } else {
        throw new Error('Error fetching speakers analysis');
      }
    })
    .then((data: Pick<SpeakersAnalysis, 'accurate' | 'time_analysis' | 'face_ids' | 'speaker_mapping'>) => {
      const { accurate, face_ids = [], time_analysis = [], speaker_mapping = {} } = data;

      if (isAnalysisAccurate === false && accurate === true) {
        clipsNeedingConfigUpdateStore.update(store => ({
          ...store,
          [id]: true
        }));
      }

      // Math.floor logic to round time to 5 decimal places
      const updatedTimes = time_analysis.map(t => roundToNDecimalPlaces(timeStringToSeconds(t.time) + start, 4));

      updateSpeakersAnalysisStoreWithId(id, {
        accurate,
        face_ids: face_ids,
        time_analysis,
        times: updatedTimes,
        visible_face_ids: visible_face_ids || face_ids.slice(0, 4),
        speaker_mapping,
        loading: false
      });
    });
}

const MAX_DURATION_TO_POLL = 2 * 60 * 1000;
export const VISION_API_POLL_INTERVAL = 3000;

export function pollForSpeakerAnalysis(
  clipId: string,
  start: number,
  visible_face_ids: number[],
  logger: AppContextState['logger'],
  maxDurationOverride?: number,
  isAnalysisAccurate?: boolean
) {
  let pollDuration = 0;
  const intervalId = setInterval(() => {
    pollDuration += VISION_API_POLL_INTERVAL;
    if (pollDuration > (maxDurationOverride || MAX_DURATION_TO_POLL)) {
      logger.warn('Speaker analysis polling duration exceeded');
      clearInterval(intervalId);
      return;
    }

    getSpeakersAnalysis(clipId, start, visible_face_ids, isAnalysisAccurate)
      .then(() => {
        if (intervalId) {
          clearInterval(intervalId);
        }
      })
      .catch(() => {
        const speakersAnalysisForClip = speakersAnalysisStore.getSnapshot()[clipId];

        if (speakersAnalysisForClip.times.length !== 0) {
          updateSpeakersAnalysisStoreWithId(clipId, defaultSpeakersAnalysis);
        }
      });
  }, VISION_API_POLL_INTERVAL);

  return intervalId;
}

export function updateVisibleFaceIds(clipId: string, ids: number[]) {
  updateSpeakersAnalysisStoreWithId(clipId, { visible_face_ids: ids });

  updateVisibleFaceIdsInClip(clipId, ids);
}

export function updateSpeakersAnalysisStoreWithId(clipId: string, data: Partial<SpeakersAnalysis>) {
  speakersAnalysisStore.update(store => ({
    ...store,
    [clipId]: {
      ...store[clipId],
      ...data
    }
  }));
}
