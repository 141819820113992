import {
  createContext,
  startTransition,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useSyncExternalStore
} from 'react';
import { FaceRecognitionStatusEnum } from '@goldcast/api/content';
import { useAppContext } from '../AppContext/AppContext';
import { getLayoutAvailabilityStatus } from './ClipsContextUtils';
import {
  clipCaptionsLoader,
  currentClip,
  initClip,
  saveClipChanges,
  clipsNeedingConfigUpdateStore,
  updateClipInList,
  updateClipWithId
} from '@/stores/clip';
import { Clip } from '@/domains/asset';
import { core, initContent, initSharedContent } from '@/stores/core';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import {
  defaultSpeakersAnalysis,
  getSpeakersAnalysis,
  initSpeakersAnalysisStore,
  VISION_API_POLL_INTERVAL,
  pollForSpeakerAnalysis,
  speakersAnalysisStore,
  updateSpeakersAnalysisStoreWithId
} from '@/stores/speakersAnalysis/speakersAnalysis';
import Loader from '@/components/atoms/Loader';
import { loadClipBrandKit, loadSharedBrandKitData } from '@/stores/brandKit';
import { SpeakersAnalysis } from '@/stores/speakersAnalysis/speakersAnalysisTypes';
import { SharedAPIStore, initSharedAPI, sharedAPI, updateClipConfig } from '@/stores/sharedAPI/sharedAPI';
import { ClipPlayerStore, defaultClipPlayerStore, initPlayerV2, playerV2 } from '@/stores/playerV2';
import { defaultSharedAPIStore } from '@/stores/sharedAPI/constants';
import featureFlagStore from '@/stores/featureFlagStore';
import { FeatureFlagKeys } from '@/services/featureFlag';
import { generateThumbnail } from '@/libs/thumbnail';

const ActionsContext = createContext<{
  setClipVideosLoading: (loading: boolean) => void;
}>({
  setClipVideosLoading: () => {}
});

export const ClipsContext = createContext<{
  clipId: string;
  clipData: Clip;
  clipVideosLoading: boolean;
  clipCaptionsLoading: boolean;
  speakersAnalysis: SpeakersAnalysis;
  sharedAPIStore: SharedAPIStore;
  playerStore: ClipPlayerStore;
  layoutStatus: FaceRecognitionStatusEnum | null;
}>({
  clipId: '',
  clipData: {} as Clip,
  clipVideosLoading: true,
  clipCaptionsLoading: false,
  speakersAnalysis: defaultSpeakersAnalysis,
  sharedAPIStore: defaultSharedAPIStore,
  playerStore: defaultClipPlayerStore,
  layoutStatus: null
});

export type ClipsContextError = { message: string; statusCode?: number };

export const ClipsContextProvider = ({
  clipId,
  children,
  renderErrorComponent,
  shared = false,
  showLoaderOnPage = false
}: {
  clipId?: string;
  children: React.ReactNode;
  renderErrorComponent?: (error: ClipsContextError, clipId: string) => JSX.Element;
  shared?: boolean;
  /** Show full page loader when loading */
  showLoaderOnPage?: boolean;
}) => {
  const { logger } = useAppContext();
  const coreStore = useSyncExternalStore(core.subscribe, core.getSnapshot);
  const [error, setError] = useState<ClipsContextError | null>(null);

  const [loading, setLoading] = useState(true);
  const [clipVideosLoading, setClipVideosLoading] = useState(true);
  const [layoutStatus, setLayoutStatus] = useState<FaceRecognitionStatusEnum | null>(null);

  const allClips = useSyncExternalStore(currentClip.subscribe, currentClip.getSnapshot);
  const clipData = allClips[clipId!] || {};

  const speakersAnalysisForAllClips = useSyncExternalStore(
    speakersAnalysisStore.subscribe,
    speakersAnalysisStore.getSnapshot
  );
  const speakersAnalysis = speakersAnalysisForAllClips[clipId!];

  const sharedAPIStoreForAllClips = useSyncExternalStore(sharedAPI.subscribe, sharedAPI.getSnapshot);
  const sharedAPIStore = sharedAPIStoreForAllClips[clipId!];

  const playerStoreForAllClips = useSyncExternalStore(playerV2.subscribe, playerV2.getSnapshot);
  const playerStore = playerStoreForAllClips[clipId!];

  const clipCaptionsLoaderState = useSyncExternalStore(clipCaptionsLoader.subscribe, clipCaptionsLoader.getSnapshot);
  const clipCaptionsLoading = clipCaptionsLoaderState[clipId!] || false;

  const clipsNeedingConfigUpdate = useSyncExternalStore(
    clipsNeedingConfigUpdateStore.subscribe,
    clipsNeedingConfigUpdateStore.getSnapshot
  );

  const initData = useCallback(async (id: string, shouldReloadClip?: boolean) => {
    sessionStorage.removeItem(`facialRecognitionStatusAndTypeFor:${id}`);
    try {
      if (!shouldReloadClip) {
        initSpeakersAnalysisStore(id);
        initSharedAPI(id);
        initPlayerV2(id);
      }

      const clip = await initClip(id);
      // Make sure to update clip in list so edit captions works in clip list
      updateClipInList(clip);

      if (shared) {
        loadSharedBrandKitData(clip.asset_metadata.font_location);
      } else {
        loadClipBrandKit(id);
      }

      setLayoutStatus(getLayoutAvailabilityStatus(clip.asset_metadata.layout, clip.layout_status));

      if (coreStore.content?.id !== clip.content.id) {
        try {
          const contentPromise = shared ? initSharedContent : initContent;

          await contentPromise(clip.broadcast);
        } catch (err: any) {
          if (err?.statusCode === 404 || err?.statusCode === 401) {
            setError(err);
          } else {
            logger.error('Error initializing content', err);
            setError({ message: 'Something went wrong on our end' });
          }
        }
      }
    } catch (err: any) {
      if (err?.statusCode === 404 || err?.statusCode === 401) {
        setError(err);
      } else {
        logger.error('Error fetching clip', err);
        setError(err);
      }
    }

    startTransition(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!clipId) {
      setError({ message: 'Clip ID not found', statusCode: 404 });
      return;
    }

    setLoading(true);
    initData(clipId);
  }, [clipId]);

  function reloadClip({ cb, showLoader }: { cb?: () => void; showLoader?: boolean } = {}) {
    if (showLoader) setLoading(true);
    initData(clipId!, true).then(() => {
      cb?.();
    });
  }

  const updateClipAndLayoutStatus = useCallback(
    (clipId: string, status: FaceRecognitionStatusEnum, isAnalysisAccurate: boolean) => {
      const newClipLayoutStatus = {
        ...clipData.layout_status,
        SPEAKER: {
          ...clipData.layout_status['SPEAKER'],
          status,
          accurate: isAnalysisAccurate
        },
        GRID: {
          ...clipData.layout_status['GRID'],
          status,
          accurate: isAnalysisAccurate
        }
      };

      const isSpeakerLayout = clipData.asset_metadata.layout === 'SPEAKER' || clipData.asset_metadata.layout === 'GRID';
      if (isSpeakerLayout) {
        setLayoutStatus(getLayoutAvailabilityStatus(clipData.asset_metadata.layout, newClipLayoutStatus));
      }

      if (status === 'DONE' || status === 'FAILED') {
        getSpeakersAnalysis(clipId, clipData.asset_metadata.start, clipData.asset_metadata.visible_face_ids!)
          .catch(() => {
            if (speakersAnalysisStore.getSnapshot()[clipId].times.length !== 0) {
              updateSpeakersAnalysisStoreWithId(clipId, defaultSpeakersAnalysis);
            }
          })
          .finally(() => {
            updateClipWithId(clipId, {
              layout_status: newClipLayoutStatus
            });

            saveClipChanges(clipId, false, false, false);

            EventBus.dispatch(CustomEvents.ReloadTemplates);
          });
      }
    },
    [clipData]
  );

  useEffect(() => {
    if (!clipId || !clipData.asset_metadata) return;

    const facialRecognitionStatusAndType = sessionStorage.getItem(`facialRecognitionStatusAndTypeFor:${clipId}`);
    if (facialRecognitionStatusAndType) {
      const [status, isAnalysisAccurate] = facialRecognitionStatusAndType.split('-');
      sessionStorage.removeItem(`facialRecognitionStatusAndTypeFor:${clipId}`);
      updateClipAndLayoutStatus(clipId, status as FaceRecognitionStatusEnum, isAnalysisAccurate === 'true');
    } else {
      setLayoutStatus(getLayoutAvailabilityStatus(clipData.asset_metadata.layout, clipData.layout_status));
    }
  }, [clipData.asset_metadata?.layout]);

  useEffect(() => {
    if (!clipId) {
      return;
    }

    if (loading) {
      return;
    }

    if (!sharedAPIStore?.mainPlayerRef) {
      return;
    }

    if (
      coreStore.content?.media_type === 'VIDEO' &&
      coreStore.content?.media_source_type === 'UPLOAD' &&
      (!speakersAnalysis || speakersAnalysis.loading)
    ) {
      return;
    }

    if (!clipsNeedingConfigUpdate[clipId]) {
      return;
    }

    generateThumbnail(clipId);
    updateClipConfig(clipId, allClips[clipId]);
    clipsNeedingConfigUpdateStore.update(data => ({ ...data, [clipId]: false }));
  }, [
    clipId,
    speakersAnalysis,
    allClips,
    loading,
    clipsNeedingConfigUpdate,
    sharedAPIStore?.mainPlayerRef,
    coreStore.content
  ]);

  const updateVisionInformation = useCallback(
    data => {
      const featureFlags = featureFlagStore.getSnapshot();

      const status = data.state.split(':')[1] as FaceRecognitionStatusEnum;
      const isAnalysisAccurate = !!data.state.includes('ACCURATE');
      const shouldUseAccurate = featureFlags[FeatureFlagKeys.Use_CL_Fast_Accurate];

      if ((shouldUseAccurate && !isAnalysisAccurate) || (!shouldUseAccurate && isAnalysisAccurate)) return;

      if (
        featureFlags[FeatureFlagKeys.Use_CL_Facial_Rec_Increment_AB] &&
        !clipData.asset_metadata &&
        (status === 'DONE' || status === 'FAILED')
      ) {
        sessionStorage.setItem(
          `facialRecognitionStatusAndTypeFor:${clipId}`,
          `${status}-${isAnalysisAccurate ? 'true' : 'false'}`
        );
        return;
      }

      if (
        !clipId ||
        data.id !== clipId ||
        !featureFlags[FeatureFlagKeys.Use_CL_Facial_Rec_Increment_AB] ||
        !clipData.asset_metadata
      )
        return;

      updateClipAndLayoutStatus(clipId, status, isAnalysisAccurate);
    },
    [layoutStatus, clipId, clipData]
  );

  useEffect(() => {
    const eventListener = EventBus.on(CustomEvents.ReloadClip, reloadClip);

    return () => {
      EventBus.off(CustomEvents.ReloadClip, eventListener);
    };
  }, []);

  useEffect(() => {
    const facialRecognitionEventListener = EventBus.on(
      CustomEvents.FacialRecognitionStatusUpdated,
      updateVisionInformation
    );

    return () => {
      EventBus.off(CustomEvents.FacialRecognitionStatusUpdated, facialRecognitionEventListener);
    };
  }, [updateVisionInformation]);

  useEffect(() => {
    if (
      !clipData.asset_metadata ||
      coreStore.content?.media_source_type !== 'UPLOAD' ||
      coreStore.content?.media_type === 'AUDIO'
    ) {
      return;
    }

    let intervalId;

    intervalId = pollForSpeakerAnalysis(
      clipId!,
      clipData.asset_metadata.start,
      clipData.asset_metadata.visible_face_ids!,
      logger,
      featureFlagStore.getSnapshot()[FeatureFlagKeys.Use_CL_Facial_Rec_Increment_AB]
        ? VISION_API_POLL_INTERVAL + 1
        : undefined,
      clipData.asset_metadata.config?.isAnalysisAccurate
    );

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [clipId, clipData.asset_metadata?.start, coreStore.content?.media_source_type, coreStore.content?.media_type]);

  const actions = useMemo(() => ({ setClipVideosLoading }), [setClipVideosLoading]);

  return loading ? (
    <div
      className={`${
        showLoaderOnPage ? 'fixed inset-0 ' : ''
      } flex h-full w-full items-center justify-center bg-white py-2`}
    >
      <Loader />
    </div>
  ) : error && clipId ? (
    renderErrorComponent ? (
      renderErrorComponent(error, clipId)
    ) : (
      <div className="flex h-full w-full select-none items-center justify-center p-2" contentEditable={false}>
        Failed to load clip. Please try again after some time.
      </div>
    )
  ) : clipId && Object.keys(clipData).length ? (
    <ActionsContext.Provider value={actions}>
      <ClipsContext.Provider
        value={{
          clipId,
          clipData,
          clipVideosLoading,
          clipCaptionsLoading,
          speakersAnalysis,
          sharedAPIStore,
          playerStore,
          layoutStatus
        }}
      >
        {children}
      </ClipsContext.Provider>
    </ActionsContext.Provider>
  ) : null;
};

export const useClipsContext = () => {
  const clipsContext = useContext(ClipsContext);
  if (clipsContext === undefined) {
    throw new Error('useClipsContext must be used within a ClipsContextProvider');
  }
  const actionsContext = useContext(ActionsContext);

  const context = useMemo(() => ({ ...clipsContext, ...actionsContext }), [clipsContext, actionsContext]);

  return context;
};
