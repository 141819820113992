import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { IconSearch } from '@tabler/icons-react';
import { useVideoImportContext } from '../../VideoImportContext';
import TemplateSelectListItem from './TemplateSelectListItem';
import { TEMPLATE_SELECT_MAX_COUNT } from './constants';
import TemplateSelectEmptyState from './TemplateSelectEmptyState';
import { classnames } from '@/libs/utils';
import Multiselect, { MultiselectOption } from '@/components/atoms/Multiselect/Multiselect';
import Loader from '@/components/atoms/Loader';
import useDebounce from '@/hooks/useDebounce';

const TABS = {
  preset: 'Preset',
  saved: 'Saved'
};

const TemplateSelect = () => {
  const { brandTemplatePreviews, selectedBrandTemplateIds, filters, setFilters } = useVideoImportContext();

  const [currentTabList, setCurrentTabList] = useState(brandTemplatePreviews.preset);
  const [currentTab, setCurrentTab] = useState<keyof typeof TABS>('preset');

  const [searchText, setSearchText] = useState(filters.title || '');

  const hasFilters = useMemo(() => filters.title || filters.size, [filters.title, filters.size]);

  useEffect(() => {
    setCurrentTabList(brandTemplatePreviews[currentTab]);
  }, [currentTab, brandTemplatePreviews]);

  const selectedTemplatesCount = useMemo(() => {
    return selectedBrandTemplateIds.preset.length + selectedBrandTemplateIds.saved.length;
  }, [selectedBrandTemplateIds.preset.length, selectedBrandTemplateIds.saved.length]);

  const currentTabLoading = useMemo(() => {
    if (currentTab === 'preset') {
      return brandTemplatePreviews.presetsLoading;
    } else {
      return brandTemplatePreviews.savedLoading;
    }
  }, [brandTemplatePreviews.presetsLoading, brandTemplatePreviews.savedLoading, currentTab]);

  const debouncedSetFilters = useDebounce(setFilters, 1000);

  const onChangeAspectRatioFilter = useCallback(
    (selectedOptions: MultiselectOption[]) => {
      setFilters(prevFilters => ({
        ...prevFilters,
        size: selectedOptions[0].value === 'all' ? undefined : selectedOptions[0].value
      }));
    },
    [setFilters]
  );

  const onChangeSearch = useCallback(
    (value: string) => {
      setSearchText(value);
      if (value !== searchText) {
        debouncedSetFilters(prevFilters => ({
          ...prevFilters,
          title: value
        }));
      }
    },
    [debouncedSetFilters, searchText]
  );

  const onClearFilters = useCallback(() => {
    setSearchText('');
    setFilters({ title: undefined, size: undefined });
  }, [setFilters]);

  return (
    <>
      <div className="sticky -top-0 z-20 flex w-full items-center justify-between border-b bg-white/[0.93] py-2.5 backdrop-blur-[20px] backdrop-saturate-[180%]">
        <div className="flex">
          <div
            className="absolute bottom-0 left-0 h-0.5 bg-black transition-all duration-300 ease-in-out"
            style={{
              width: '12rem',
              transform: `translateX(${currentTab === Object.keys(TABS)[1] ? '13rem' : '1rem'})`
            }}
          />
          {Object.keys(TABS).map(key => (
            <button
              key={key}
              data-testid={`template-select-tab-${key}`}
              className={classnames({
                'text-black': currentTab === key,
                'text-slate-500': currentTab !== key,
                'relative z-10 flex w-48 items-center justify-center px-4 py-2 font-medium': true
              })}
              onClick={() => setCurrentTab(key as keyof typeof TABS)}
            >
              {TABS[key]}
              {selectedBrandTemplateIds[key].length > 0 && (
                <span className="ml-1 rounded-full bg-gray-200 px-2 py-0.5 text-xs">
                  {selectedBrandTemplateIds[key].length}
                </span>
              )}
            </button>
          ))}
        </div>
        <div className="flex w-full items-center justify-end gap-2">
          <div
            className={classnames({
              'mr-2.5 flex items-center justify-center rounded-full px-3 py-1 text-xs font-medium': true,
              'bg-black text-white': selectedTemplatesCount === TEMPLATE_SELECT_MAX_COUNT,
              'bg-slate-100 text-slate-700': selectedTemplatesCount !== TEMPLATE_SELECT_MAX_COUNT
            })}
          >
            <span>
              {selectedTemplatesCount}/{TEMPLATE_SELECT_MAX_COUNT} Templates
            </span>
          </div>
          <Multiselect
            allSelectedLabel="All Aspect Ratios"
            options={[
              { label: 'Portrait (9:16)', value: 'PORTRAIT' },
              { label: 'Landscape (16:9)', value: 'LANDSCAPE' },
              { label: 'Square (1:1)', value: 'SQUARE' }
            ]}
            variant="single"
            onChange={onChangeAspectRatioFilter}
          />
          <div className="relative flex items-center gap-2 rounded-lg border text-slate-700 focus-within:border-deep-orange focus-within:text-deep-orange">
            <input
              type="text"
              className="h-10 w-80 rounded-lg border-none px-10 text-sm text-black ring-0 placeholder:text-slate-500 focus:border-none focus:outline-none focus:ring-0"
              placeholder="Search templates..."
              value={searchText}
              onChange={e => onChangeSearch(e.target.value)}
            />
            <IconSearch className="absolute left-3 h-5 w-5" />
          </div>
        </div>
      </div>
      <div className="relative h-full p-4">
        {currentTabLoading ? (
          <div className="flex h-full items-center justify-center">
            <Loader />
          </div>
        ) : currentTabList.length > 0 ? (
          <div className="grid h-full w-full grid-cols-4 gap-6 pb-6">
            {currentTabList.map(template => (
              <TemplateSelectListItem template={template} key={template.id} />
            ))}
          </div>
        ) : currentTab === 'saved' && brandTemplatePreviews.saved.length === 0 && !hasFilters ? (
          <TemplateSelectEmptyState
            title="No Saved Templates Yet"
            description="Start creating and saving your own templates to see them here. Customize and reuse your favorite designs!"
            onActionClick={() => setCurrentTab('preset')}
            actionText="Explore Preset Templates"
          />
        ) : currentTabList.length === 0 ? (
          <TemplateSelectEmptyState
            title="No Templates Found"
            description="Clear the filter or search for a different term."
            onActionClick={onClearFilters}
            actionText="Clear Filters"
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default memo(TemplateSelect);
