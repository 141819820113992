import React, { memo, useEffect, useState } from 'react';
import { getClipThumbnailUrl } from '../Sessions/AssetsTable/ClipPreview/ClipPreviewUtils';
import ClipListItemInterimThumbnail from '../Clip/ClipInterimThumbnail/ClipListItemInterimThumbnail';
import { isAudioContent } from '@/stores/core';
import Icon from '@/components/atoms/Icon';
import { classnames } from '@/libs/utils';
import { Clip } from '@/domains/asset';
import EventBus from '@/libs/eventBus/eventBus';
import { CustomEvents } from '@/libs/eventBus/constants';
import ErrorBoundaryForComponent from '@/App/ErrorBoundary/ErrorBoundaryForComponent';

enum ClipListItemThumbnailType {
  Image = 'image',
  Interim = 'interim',
  Error = 'error'
}

function ClipListItemThumbnail({
  clip,
  isClipActive,
  isClipUnseen
}: {
  clip: Clip;
  isClipActive: boolean;
  isClipUnseen: boolean;
}) {
  const isAudio = isAudioContent();

  const [thumbnailType, setThumbnailType] = useState<ClipListItemThumbnailType>(
    clip.asset_metadata.config ? ClipListItemThumbnailType.Image : ClipListItemThumbnailType.Interim
  );
  const [thumbnailUrl, setThumbnailUrl] = React.useState<string>(`${getClipThumbnailUrl(clip)}?${Math.random()}`);
  const [isThumbnailChanging, setIsThumbnailChanging] = useState(false);

  useEffect(() => {
    const eventListener = EventBus.on(CustomEvents.ThumbnailGenerated, res => {
      if (clip.asset_metadata.id === res.clip.asset_metadata.id) {
        setIsThumbnailChanging(true);
        setThumbnailUrl(`${getClipThumbnailUrl(clip)}?${Math.random()}`);
        setThumbnailType(ClipListItemThumbnailType.Image);
      }
    });
    return () => {
      EventBus.off(CustomEvents.ThumbnailGenerated, eventListener);
    };
  }, [clip]);

  function onThumbnailLoaded() {
    setIsThumbnailChanging(false);
  }

  function onThumbnailLoadError() {
    setThumbnailType(ClipListItemThumbnailType.Error);
  }

  return (
    <div
      className={classnames(
        'relative flex items-center justify-center overflow-hidden rounded-md bg-cover bg-center bg-no-repeat shadow-lg',
        {
          'aspect-square w-[62%]': clip.asset_metadata.size === 'SQUARE',
          'aspect-[9/16] w-[35%]': clip.asset_metadata.size === 'PORTRAIT',
          'aspect-video w-[85%]': clip.asset_metadata.size === 'LANDSCAPE',
          '!border-none': isClipUnseen && !isClipActive
        }
      )}
    >
      {thumbnailType === ClipListItemThumbnailType.Image ? (
        <div>
          <img
            className={classnames('mx-auto transition-all duration-300', isThumbnailChanging ? 'blur-sm' : 'blur-0')}
            src={thumbnailUrl}
            alt="Preview"
            onLoad={onThumbnailLoaded}
            onError={onThumbnailLoadError}
          />
        </div>
      ) : thumbnailType === ClipListItemThumbnailType.Interim ? (
        <ErrorBoundaryForComponent onError={onThumbnailLoadError}>
          <ClipListItemInterimThumbnail clip={clip} />
        </ErrorBoundaryForComponent>
      ) : (
        <div className="flex h-6 w-6 items-center justify-center rounded-full border border-slate-300">
          <Icon name={isAudio ? 'IconHeadphones' : 'IconVideo'} size={13} stroke={2} className="text-slate-400/70" />
        </div>
      )}
    </div>
  );
}

export default memo(ClipListItemThumbnail);
