import * as filestack from 'filestack-js';
import { getEnvConfig } from '@/constants';
import { getFileExtension } from '@/libs/file';

export function getContentStoreConfig(
  filePath: string,
  fileId: string,
  baseFilePath: string
): filestack.StoreUploadOptions {
  return {
    location: 's3',
    container: getEnvConfig('S3_STATIC_ASSETS_BUCKET'),
    path: `/${filePath}`,
    region: getEnvConfig('AWS_STORE_REGION'),
    disableStorageKey: true,
    filename: file => getFileName(file, fileId, baseFilePath)
  };
}

function getFileName(file: File, fileId: string, baseFilePath: string): string {
  let extension = getFileExtension(file.name);
  return `${baseFilePath}/${fileId}${extension}`;
}

export function arraysEqual(a: string[], b: string[]): boolean {
  return JSON.stringify(a) === JSON.stringify(b);
}

export function getVoiceProfileLimitMessage(voiceProfileCount: number) {
  return `Your account is limited to ${voiceProfileCount} voice ${
    voiceProfileCount > 1 ? 'profiles' : 'profile'
  }. Please delete an existing one or contact sales to upgrade your plan.`;
}
