import { memo, useSyncExternalStore } from 'react';
import { Outlet } from 'react-router-dom';
import SessionDetailBackButton from '../SessionDetail/SessionDetailBackButton';
import MockTemplateBackgroundTransition from './MockTemplateBackgroundTransition/MockTemplateBackgroundTransition';
import VideoImportDialog from './VideoImportDialog/VideoImportDialog';
import { VideoImportContextProvider } from './VideoImportContext';
import { firstTimeUserStore } from '@/stores/firstTimeUserStore';

function VideoImportPage() {
  const isFirstTimeUser = useSyncExternalStore(firstTimeUserStore.subscribe, firstTimeUserStore.getSnapshot);

  return (
    <VideoImportContextProvider>
      <div className="absolute inset-0 h-full w-full overflow-hidden bg-slate-50">
        <MockTemplateBackgroundTransition />
        {!isFirstTimeUser && (
          <div className="absolute left-4 top-4 z-[100]">
            <SessionDetailBackButton />
          </div>
        )}
        <div className="pointer-events-none absolute inset-0">
          <VideoImportDialog />
        </div>
        <Outlet />
      </div>
    </VideoImportContextProvider>
  );
}

export default memo(VideoImportPage);
